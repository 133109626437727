<template>
  <div>
    <base-section
      id="serviceproviderfaq-account"
      space="56"
    >
      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <base-info-card :title="$t('faq.title')" />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-card>
          <v-toolbar
            flat
            color="primary"
            dark
          >
            <v-toolbar-title>THEME 1</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab>
              Sujet 1
            </v-tab>
            <v-tab>
              Sujet 2
            </v-tab>
            <v-tab>
              Sujet 3
            </v-tab>
            <!------------------------ DETAIL TAB 1    ------------------------------------------------>
            <v-tab-item>
              <v-card flat>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Question 1
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Réponse 1.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Question 2
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Réponse 2.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                  </p>

                  <p class="mb-0">
                    Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'

  @Component({ name: 'AnonymousFaqAccount' })
  class AnonymousFaqAccount extends Vue {

  }

  export default AnonymousFaqAccount
</script>
